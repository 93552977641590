import { createStore } from 'effector'

import { 
  getUserDetails,
  editUserName,
  finalizeProfileImageUpload
} from './effects'
import { saveProfileError } from './events'

const profile = createStore({ error: null, isLoading: false })

profile
  .on(saveProfileError, (state, error) => {
    console.log('SAVED PROFILE ERROR')
    return { ...state, error }
  })

  .on(getUserDetails, state => {
    console.log('PENDING FOR USER DETAILS')
    return { ...state, isLoading: true }
  })
  .on(getUserDetails.done, (state, { result }) => {
    console.log('DOWNLOADED USER DETAILS')
    return { ...state, ...result, isLoading: false }
  })
  .on(getUserDetails.fail, (state, { result }) => {
    console.log('ERROR FROM USER DETAILS')
    return { ...state, error: 'Something wrong with API', isLoading: false }
  })

  .on(editUserName, state => {
    console.log('PENDING FOR EDIT USER NAME')
    return { ...state, isLoading: true }
  })
  .on(editUserName.done, (state, { result }) => {
    console.log('CHANGED USER NAME', result)
    return { ...state, isLoading: false, displayName: result.data.displayName }
  })
  .on(editUserName.fail, (state, { error }) => {
    console.log('ERROR FROM EDIT USER NAME', error)
    return { ...state, isLoading: false, error: error.errorMessage }
  })

  .on(finalizeProfileImageUpload, state => {
    console.log('PENDING FOR FINALIZE PROFILE IMAGE UPLOAD')
    return { ...state, isLoading: true }
  })
  .on(finalizeProfileImageUpload.done, (state, { result }) => {
    console.log('FINALIZED PROFILE IMAGE UPLOAD')
    return { ...state, ...result.data, isLoading: false }
    
  })
  .on(finalizeProfileImageUpload.fail, (state, { error }) => {
    console.log('ERROR FROM FINALIZE PROFILE IMAGE UPLOAD', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })


export default profile
