import axios from 'axios'

class Api {
  constructor () {
    const service = axios.create({
      baseURL: process.env.GATSBY_API_URL
    })
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }

  handleSuccess (response) {
    return response
  }

  handleError = error => {
    return Promise.reject(error)
  }

  get (path, options, callback) {
    return this.service
      .get(path, options)
      .then(response => callback(response.status, response.data))
  }

  req (options, callback) {
    return this.service
      .request(options)
      .then(response => callback(response.status, response))
  }
}

export default new Api()
