import { createEvent } from 'effector'

// AUTH STORE
export const saveAccessToken = createEvent('save token')

// PROFILE STORE
export const saveProfileError = createEvent('save profile error')

// RESYKABLES STORE
export const saveResykablesError = createEvent('save resykables error')
