import { createEffect } from 'effector'
import Api from 'services/api'

import { store } from 'components/common/GlobalState'

/* USER */

export const getUserDetails = createEffect('Get user details')

getUserDetails.use(async token => {
	const req = await Api.get(
		'/account/UserDetails/',
		{
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${token}`
			}
		},
		(status, { data }) => {
			console.log('Response from USER DETAILS is:', status)
			return data
		}
	)

	return req
})

export const editUserName = createEffect('Edit user name')

editUserName.use(async ({ name }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	console.log({name, TOKEN});
	const req = await Api.req(
		{
			method: 'patch',
			url: '/account/UserDetails',
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			},
			data: {
				displayName: name
			}
		},

		(status, { data }) => {
			console.log('Response from USERNAME EDIT is:', status)
			if (!data.isSuccess) {
				return Promise.reject(data)
			}
			return data
		}
	)

	return req
})

/* RESYKABLE */

export const getResykables = createEffect('Get resykables')

getResykables.use(async token => {
	const req = await Api.get(
		'/registry/resykables/',
		{
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${token}`
			}
		},
		(status, { data }) => {
			console.log('Response from RESYKABLES is:', status)
			return data
		}
	)

	return req
})

export const createResykable = createEffect('Create resykable')

createResykable.use(
	async ({ title, description, purchaseDate, purchasePrice, purchasedFrom, purchaseState }) => {
		const {
			auth: { accessToken: TOKEN }
		} = store.getState()
		const req = await Api.req(
			{
				method: 'post',
				url: `/registry/resykables`,
				responseType: 'json',
				headers: {
					'Content-Type': 'application/json',
					accept: 'application/json',
					Authorization: `Bearer ${TOKEN}`
				},
				data: {
					title,
					description,
					purchaseDate,
					purchasePrice,
					purchasedFrom,
					purchaseState
				}
			},

			(status, { data }) => {
				console.log('Response from RESYKABLE is:', status)
				return data
			}
		)

		return req
	}
)

export const editResykable = createEffect('Edit resykable')

editResykable.use(
	async ({
		resykableGuid,
		title,
		description,
		purchaseDate,
		purchasePrice,
		purchasedFrom,
		purchaseState,
		isOnShow
	}) => {
		const {
			auth: { accessToken: TOKEN }
		} = store.getState()
		const req = await Api.req(
			{
				method: 'put',
				url: `/registry/resykables/${resykableGuid}`,
				responseType: 'json',
				headers: {
					'Content-Type': 'application/json',
					accept: 'application/json',
					Authorization: `Bearer ${TOKEN}`
				},
				data: {
					title,
					description,
					purchaseDate,
					purchasePrice,
					purchasedFrom,
					purchaseState,
					isOnShow
				}
			},

			(status, { data }) => {
				console.log('Response from EDIT RESYKABLE is:', status)
				return data
			}
		)

		return req
	}
)

export const deleteResykable = createEffect('Delete resykable')

deleteResykable.use(
	async ({ resykableGuid }) => {
		const {
			auth: { accessToken: TOKEN }
		} = store.getState()

		const req = await Api.req(
			{
				method: 'delete',
				url: `/registry/resykable/${resykableGuid}`,
				responseType: 'json',
				headers: {
					'Content-Type': 'application/json',
					accept: 'application/json',
					Authorization: `Bearer ${TOKEN}`
				}
			},

			(status, { data }) => {
				console.log('Response from DELETE RESYKABLE is:', status)
				return data
			}
		)

		return req
	}
)


export const shareResykable = createEffect('Share resykable')

shareResykable.use(async ({ id, isOnShow, description }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = await Api.req(
		{
			method: 'put',
			url: `/registry/resykables/${id}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			},
			data: {
				isOnShow: !isOnShow,
				description
			}
		},

		(status, { data }) => {
			console.log('Response from RESYKABLE is:', status)
			return data
		}
	)

	return req
})

export const editResykableDesc = createEffect('Edit resykable description')

editResykableDesc.use(async ({ id, isOnShow, description }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = Api.req(
		{
			method: 'put',
			url: `/registry/resykables/${id}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			},
			data: {
				description,
				isOnShow
			}
		},

		(status, { data }) => {
			console.log('Response from RESYKABLE DESC is:', status)
			return data
		}
	)
	return req
})

export const shareResykableImage = createEffect('Share resykable image')

shareResykableImage.use(async ({ imageId, share }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = await Api.req(
		{
			method: 'post',
			url: `/registry/images/${imageId}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			},
			data: {
				visibleInShowroom: share
			}
		},

		(status, { data }) => {
			console.log('Response from RESYKABLE IMAGE SHARE is:', status)
			return data
		}
	)

	return req
})

export const removeResykableImage = createEffect('Remove resykable image')

removeResykableImage.use(async ({ imageId, resykableGuid }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = await Api.req(
		{
			method: 'delete',
			url: `/registry/images/${imageId}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			}
		},

		(status, { data }) => {
			console.log('Response from REMOVED RESYKABLE IMAGE is:', status)
			return { data, resykableGuid }
		}
	)

	return req
})

export const replaceResykableImage = createEffect('Replace resykable image')

replaceResykableImage.use(async ({ imageId, resykableId, targetedId }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = await Api.req(
		{
			method: 'post',
			url: `/registry/resykables/${targetedId}/import/${imageId}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			}
		},

		(status, { data }) => {
			console.log('Response from REPLACED RESYKABLE IMAGE is:', status)
			return data
		}
	)

	return req
})

export const initializeImageUpload = createEffect('Initialize image upload')

initializeImageUpload.use(async ({ fileName }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = await Api.req(
		{
			method: 'post',
			url: `/upload/InitializeImageUpload?fileName=${encodeURI(fileName)}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			}
		},

		(status, { data }) => {
			console.log('Response from INITIALIZE IMAGE UPLOAD is:', status)
			return data
		}
	)

	return req
})

export const uploadImage = createEffect('Upload image')

uploadImage.use(async ({ fileUploadUri, file }) => {
	const getRequestData = async () =>
		new Promise((resolve, reject) => {
			var reader = new FileReader()
			reader.readAsArrayBuffer(file)
			reader.onloadend = event => {
				let target = event.target
				if (target.readyState == reader.DONE) {
					const requestData = new Uint8Array(target.result)
					resolve(requestData)
				}
			}
		})

	const requestData = await getRequestData()
	const req = await fetch(fileUploadUri, {
		method: 'put',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'X-File-Name': file.name,
			'x-ms-blob-type': 'BlockBlob',
			'Content-Type': file.type || 'application/octet-stream',
			'x-ms-blob-content-type': file.type || 'application/octet-stream'
		},
		body: requestData
	})

	return req
})

export const updateImage = createEffect('Update image')

updateImage.use(async ({ imageGuid, visibleInShowroom, description }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()

	const req = await Api.req(
		{
			method: 'post',
			url: `/registry/images/${imageGuid}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			},
			data: {
				visibleInShowroom,
				description
			}
		},

		(status, { data }) => {
			console.log('Response from UPDATE IMAGE is:', status)
			return data
		}
	)

	return req
})

export const finalizeImageUpload = createEffect('Finalize image upload')

finalizeImageUpload.use(async ({ uploadSessionGuid, resykableGuid }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = await Api.req(
		{
			method: 'post',
			url: `/upload/FinalizeImageUpload?uploadSessionGuid=${uploadSessionGuid}&resykableGuid=${resykableGuid}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			}
		},

		(status, { data }) => {
			console.log('Response from FINALIZE IMAGE UPLOAD is:', status)
			return data
		}
	)

	return req
})

export const finalizeProfileImageUpload = createEffect('Finalize profile image upload')

finalizeProfileImageUpload.use(async ({ uploadSessionGuid }) => {
	const {
		auth: { accessToken: TOKEN }
	} = store.getState()
	const req = await Api.req(
		{
			method: 'post',
			url: `/upload/FinalizeProfileImageUpload?uploadSessionGuid=${uploadSessionGuid}`,
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json',
				Authorization: `Bearer ${TOKEN}`
			}
		},

		(status, { data }) => {
			console.log('Response from FINALIZE PROFILE IMAGE UPLOAD is:', status)
			return data
		}
	)

	return req
})

/* SHOWROOM */

export const getPublicShowroom = createEffect('Get public showroom')

getPublicShowroom.use(async ({ resykableGuid }) => {
	const req = await Api.get(
		`/showroom/resykables/${resykableGuid}/`,
		{
			responseType: 'json',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json'
			}
		},
		(status, { data }) => {
			console.log('Response from PUBLIC SHOWROOM is:', status)
			return data
		}
	)

	return req
})
