import { createStore } from 'effector'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'

import {
  getResykables,
  createResykable,
  editResykable,
  deleteResykable,
  shareResykable,
  editResykableDesc,
  shareResykableImage,
  removeResykableImage,
  replaceResykableImage,
  initializeImageUpload,
  uploadImage,
  updateImage,
  finalizeImageUpload,
  getPublicShowroom
} from './effects'

import { saveResykablesError } from './events'

const resykables = createStore({ error: null, isLoading: false, data: undefined })

resykables
  .on(saveResykablesError, (state, error) => {
    console.log('SAVED RESYKABLES ERROR')
    return { ...state, error }
  })

  .on(getResykables, state => {
    console.log('PENDING FOR GET RESYKABLES')
    return { ...state, isLoading: true }
  })
  .on(getResykables.done, (state, { result }) => {
    console.log('DOWNLOADED RESYKABLES')
    return { ...state, isLoading: false, data: result }
  })
  .on(getResykables.fail, (state, { error }) => {
    console.log('ERROR FROM GET RESYKABLES', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(createResykable, state => {
    console.log('PENDING FOR CREATE RESYKABLE')
    return { ...state, isLoading: true }
  })
  .on(createResykable.done, (state, { result: { data: response } }) => {
    console.log('CREATED RESYKABLE')
    const newData = cloneDeep(state.data)
    newData.push(response)
    return { ...state, isLoading: false, data: newData }
  })
  .on(createResykable.fail, (state, { error }) => {
    console.log('ERROR FROM CREATE RESYKABLES', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(editResykable, state => {
    console.log('PENDING FOR EDIT RESYKABLE')
    return { ...state, isLoading: true }
  })
  .on(editResykable.done, (state, { result: { data: response } }) => {
    console.log('EDITED RESYKABLE')
    const resykableIndex = findIndex(state.data, { guid: response.guid })
    const newData = cloneDeep(state.data)
    newData[resykableIndex] = response
    return { ...state, isLoading: false, data: newData }
  })
  .on(editResykable.fail, (state, { error }) => {
    console.log('ERROR FROM EDIT RESYKABLES', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(deleteResykable, state => {
    console.log('PENDING FOR DELETE RESYKABLE')
    return { ...state, isLoading: true }
  })
  .on(deleteResykable.done, (state, { params }) => {
    console.log('DELETED RESYKABLE')
    const resykableIndex = findIndex(state.data, { guid: params.resykableGuid })
    const newData = cloneDeep(state.data)
    newData.splice(resykableIndex, 1)
    return { ...state, isLoading: false, data: newData }
  })
  .on(deleteResykable.fail, (state, { error }) => {
    console.log('ERROR FROM DELETE RESYKABLES', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(shareResykable, state => {
    console.log('PENDING FOR SHARE RESYKABLE')
    return { ...state, isLoading: true }
  })
  .on(shareResykable.done, (state, { result: { data: response } }) => {
    console.log('SHARED/UNSHARED RESYKABLE')
    const resykableIndex = findIndex(state.data, { guid: response.guid })
    const newData = cloneDeep(state.data)
    newData[resykableIndex] = response
    return { ...state, isLoading: false, data: newData }
  })
  .on(shareResykable.fail, (state, { error }) => {
    console.log('ERROR FROM SHARED/UNSHARED RESYKABLE')
    const parsedError = error.toJSON() || 'Problem with API'
    return { ...state, isLoading: false, error: parsedError.message }
  })

  .on(editResykableDesc, state => {
    console.log('PENDING FOR EDIT RESYKABLE DESC')
    return { ...state, isLoading: true }
  })
  .on(editResykableDesc.done, (state, { result: { data: response } }) => {
    console.log('EDITED RESYKABLE DESCRIPTION')
    const resykableIndex = findIndex(state.data, { guid: response.guid })
    const newData = cloneDeep(state.data)
    newData[resykableIndex] = response
    return { ...state, isLoading: false, data: newData }
  })
  .on(editResykableDesc.fail, (state, { error }) => {
    console.log('ERROR FROM EDIT RESYKABLE')
    const parsedError = error.toJSON() || 'Problem with API'
    return { ...state, isLoading: false, error: parsedError.message }
  })

  .on(shareResykableImage, state => {
    console.log('PENDING FOR SHARE RESYKABLE IMAGE')
    return { ...state, isLoading: true }
  })
  .on(shareResykableImage.done, (state, { params }) => {
    console.log('SHARED/UNSHARED RESYKABLE IMAGE')
    const resykableIndex = findIndex(state.data, { guid: params.resykableId })
    const resykableImageIndex = findIndex(state.data[resykableIndex].images, {
      guid: params.imageId
    })
    const newData = cloneDeep(state.data)
    newData[resykableIndex].images[resykableImageIndex].visibleInShowroom =
      params.share
    return { ...state, isLoading: false, data: newData }
  })
  .on(shareResykableImage.fail, (state, { error }) => {
    console.log('ERROR FROM SHARE RESYKABLE IMAGE')
    const parsedError = error.toJSON() || 'Problem with API'
    return { ...state, isLoading: false, error: parsedError.message }
  })

  .on(removeResykableImage, state => {
    console.log('PENDING FOR REMOVE RESYKABLE IMAGE')
    return { ...state, isLoading: true }
  })
  .on(removeResykableImage.done, (state, { params }) => {
    console.log('REMOVED RESYKABLE IMAGE')
    const resykableIndex = findIndex(state.data, { guid: params.resykableGuid })
    const resykableImageIndex = findIndex(state.data[resykableIndex].images, {
      guid: params.imageId
    })
    const newData = cloneDeep(state.data)
    newData[resykableIndex].images.splice(resykableImageIndex, 1)
    return { ...state, isLoading: false, data: newData }
  })
  .on(removeResykableImage.fail, (state, { error }) => {
    console.log('ERROR FROM REMOVE RESYKABLE IMAGE')
    const parsedError = error.toJSON() || 'Problem with API'
    return { ...state, isLoading: false, error: parsedError.message }
  })

  .on(replaceResykableImage, state => {
    console.log('PENDING FOR REPLACE RESYKABLE IMAGE')
    return { ...state, isLoading: true }
  })
  .on(
    replaceResykableImage.done,
    (state, { params, result: { isSuccess, data } }) => {
      console.log('REPLACED RESYKABLE IMAGE')
      const targetedResykableIndex = findIndex(state.data, {
        guid: params.targetedId
      })
      const resykableIndex = findIndex(state.data, { guid: params.resykableId })
      const resykableImageIndex = findIndex(state.data[resykableIndex].images, {
        guid: params.imageId
      })
      const newData = cloneDeep(state.data)

      if (targetedResykableIndex >= 0) {
        newData[resykableIndex].images.splice(resykableImageIndex, 1)
        newData[targetedResykableIndex] = data
      } else {
        newData[resykableIndex].images.splice(resykableImageIndex, 1)
        newData.push(data)
      }
      return { ...state, isLoading: false, data: newData }
    }
  )
  .on(replaceResykableImage.fail, (state, { error }) => {
    console.log('ERROR FROM REPLACE RESYKABLE IMAGE')
    const parsedError = error.toJSON() || 'Problem with API'
    return { ...state, isLoading: false, error: parsedError.message }
  })

  .on(initializeImageUpload, state => {
    console.log('PENDING FOR INITIALIZE IMAGE UPLOAD')
    return { ...state, isLoading: true }
  })
  .on(initializeImageUpload.done, (state, { result: { data: response } }) => {
    console.log('INITIALIZED IMAGE UPLOAD')
    return { ...state, isLoading: false, image_upload: response }
  })
  .on(initializeImageUpload.fail, (state, { error }) => {
    console.log('ERROR FROM INITIALIZE IMAGE UPLOAD', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(uploadImage, state => {
    console.log('PENDING FOR UPLOAD IMAGE')
    return { ...state, isLoading: true }
  })
  .on(uploadImage.done, (state, data) => {
    console.log('UPLOADED IMAGE')
    return { ...state, isLoading: false, image_upload: data }
  })
  .on(uploadImage.fail, (state, { error }) => {
    console.log('ERROR FROM UPLOAD IMAGE', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(updateImage, state => {
    console.log('PENDING FOR UPDATE IMAGE')
    return { ...state, isLoading: true }
  })
  .on(updateImage.done, (state, { params, result: { data: response } }) => {
    console.log('UPDATED IMAGE')
    const resykableIndex = state.data.findIndex(
      resykable =>
        findIndex(resykable.images, { guid: params.imageGuid }) !== -1
    )
    const resykableImageIndex = findIndex(state.data[resykableIndex].images, {
      guid: params.imageGuid
    })
    const newData = cloneDeep(state.data)
    newData[resykableIndex].images[resykableImageIndex] = response
    return { ...state, isLoading: false, data: newData }
  })
  .on(updateImage.fail, (state, { error }) => {
    console.log('ERROR FROM UPDATE IMAGE', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(finalizeImageUpload, state => {
    console.log('PENDING FOR FINALIZE IMAGE UPLOAD')
    return { ...state, isLoading: true }
  })
  .on(finalizeImageUpload.done, (state, { result: { data: response } }) => {
    console.log('FINALIZED IMAGE UPLOAD')
    const resykableIndex = findIndex(state.data, { guid: response.guid })
    const newData = cloneDeep(state.data)
    newData[resykableIndex] = response
    return {
      ...state,
      isLoading: false,
      data: newData,
      image_upload: null
    }
  })
  .on(finalizeImageUpload.fail, (state, { error }) => {
    console.log('ERROR FROM FINALIZE IMAGE UPLOAD', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

  .on(getPublicShowroom, state => {
    console.log('PENDING FOR GET PUBLIC SHOWROOM')
    return { ...state, isLoading: true }
  })
  .on(getPublicShowroom.done, (state, { result }) => {
    console.log('DOWNLOADED PUBLIC SHOWROOM')
    return { ...state, isLoading: false, showroom: result }
  })
  .on(getPublicShowroom.fail, (state, { error }) => {
    console.log('ERROR FROM GET PUBLIC SHOWROOM', error)
    return { ...state, isLoading: false, error: 'Problem with API' }
  })

export default resykables
