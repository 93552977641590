import { createStoreObject } from 'effector'

// STORES
import auth from './store.auth.js'
import profile from './store.profile.js'
import resykables from './store.resykables.js'

// EVENTS
import {
  saveAccessToken,
  saveProfileError,
  saveResykablesError
} from './events'

// EFFECTS
import {
  getUserDetails,
  editUserName,
  getResykables,
  createResykable,
  editResykable,
  deleteResykable,
  shareResykable,
  editResykableDesc,
  shareResykableImage,
  removeResykableImage,
  replaceResykableImage,
  initializeImageUpload,
  uploadImage,
  updateImage,
  finalizeImageUpload,
  finalizeProfileImageUpload,
  getPublicShowroom
} from './effects'

// CREATE GLOBAL STORE
const store = createStoreObject({ auth, profile, resykables })

store.watch(state => console.log('AUTH STATE: ', state))

export {
  store,
  saveAccessToken,
  saveProfileError,
  saveResykablesError,
  getUserDetails,
  editUserName,
  getResykables,
  createResykable,
  editResykable,
  deleteResykable,
  shareResykable,
  editResykableDesc,
  shareResykableImage,
  removeResykableImage,
  replaceResykableImage,
  initializeImageUpload,
  uploadImage,
  updateImage,
  finalizeImageUpload,
  finalizeProfileImageUpload,
  getPublicShowroom
}
