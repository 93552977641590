/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// exports.onClientEntry = () => {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.getRegistrations('/').then(registrations => {
//       registrations.map(reg => reg.unregister())
//     })
//   }
// }
import React, { useEffect } from 'react'

import useAuth from 'utils/hooks/useAuth'
import { saveAccessToken } from 'components/common/GlobalState'

import Spinner from 'components/common/Spinner'

const SessionCheck = ({ children }) => {
  const { isLoading, accessToken } = useAuth()

  useEffect(() => {
    accessToken && saveAccessToken(accessToken)
  }, [accessToken])

  return isLoading ? <Spinner /> : children
}

export const wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>
}
