import React, { useState, useEffect } from 'react'
import auth, { SessionState } from 'services/auth'

const useAuth = (stateCallback = (state = SessionState) => {}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(auth.isAuthenticated())
  const [profile, setProfile] = useState(auth.getUserProfile())
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    // Override `sessionStateCallback` in auth service
    auth.sessionStateCallback = state => {
      stateCallback(state)
      setIsLoggedIn(state.isLoggedIn)
    }
    ;(async () => {
      try {
        await auth.checkSession()
        const user = auth.getUserProfile()
        const token = auth.getAccessToken()
        setProfile(user)
        setAccessToken(token)
      } catch (error) {
        console.log(`Error: ${error}`)
      }

      setIsLoading(false)
    })()

    return () => {
      // Clean up sessionStateCallback
      auth.sessionStateCallback = () => {}
    }
  }, [])

  return {
    isLoading,
    isLoggedIn,
    profile,
    accessToken
  }
}

export default useAuth
