import auth0 from 'auth0-js'
import { navigate } from 'gatsby'

const isBrowser = typeof window !== 'undefined'

export const SessionState = {
	isLoggedIn: false,
	userProfile: {},
	accessToken: null
}

class Auth {
	constructor() {
		this._accessToken = null
		this._userProfile = {}
		this._AUTH = isBrowser
			? new auth0.WebAuth({
					domain: process.env.GATSBY_AUTH0_DOMAIN,
					clientID: process.env.GATSBY_AUTH0_CLIENTID,
					redirectUri: process.env.GATSBY_AUTH0_CALLBACK_URL,
					responseType: 'token id_token',
					scope: 'openid profile email',
					audience: process.env.GATSBY_AUTH0_AUDIENCE_URL
			  })
			: undefined
	}

	setSession = authResult => {
		if (!isBrowser) return

		localStorage.setItem('isLoggedIn', 'true')
		this._accessToken = authResult.accessToken
		this._userProfile = authResult.idTokenPayload

		this.sessionStateCallback({
			isLoggedIn: true,
			userProfile: this._userProfile,
			accessToken: this._accessToken
		})
	}

	getAccessToken = () => this._accessToken

	getUserProfile = () => this._userProfile

	sessionStateCallback = (state = SessionState) => {}

	login = () => {
		console.log('LOGIN')
		if (!isBrowser) return
		// Save postLoginUrl so we can redirect user back to where they left off after login screen
		// localStorage.setItem('postLoginUrl', window.location.pathname)
		/* For some reason firefox and safari don't like window.location.pathname in this situation */
		localStorage.setItem('postLoginUrl', '/app')
		this._AUTH && this._AUTH.authorize()
		// https://community.auth0.com/t/how-to-open-login-signup-on-the-universal-login-hosted-page/12642
	}

	handleAuthentication = () => {
		return new Promise((resolve, reject) => {
			this._AUTH &&
				this._AUTH.parseHash((err, authResult) => {
					console.log('TCL: Auth -> handleAuthentication -> authResult', authResult)
					if (authResult && authResult.accessToken && authResult.idToken) {
						this.setSession(authResult)

						const postLoginUrl = localStorage.getItem('postLoginUrl')
						console.log('TCL: Auth -> handleAuthentication -> postLoginUrl', postLoginUrl)
						localStorage.removeItem('postLoginUrl')
						if (postLoginUrl) {
							navigate(postLoginUrl)
						} else {
							navigate('/app')
						}
						return resolve(authResult)
					} else if (err) {
						return reject(err)
					}
					return resolve()
				})
		})
	}

	checkSession = () => {
		return new Promise(resolve => {
			this._AUTH &&
				this._AUTH.checkSession({}, (err, authResult) => {
					if (authResult && authResult.accessToken && authResult.idToken) {
						this.setSession(authResult)
						return resolve(authResult)
					}
					if (err && err.error === 'login_required') {
						// User has been logged out from Auth0 server.
						// Remove local session.
						this.localLogout()
					}
					return resolve()
				})
		})
	}

	localLogout = () => {
		if (!isBrowser) return
		// Remove tokens and user profile
		this._accessToken = undefined
		this._userProfile = undefined

		localStorage.removeItem('isLoggedIn')
		this.sessionStateCallback({
			isLoggedIn: false,
			userProfile: undefined,
			accessToken: undefined
		})
	}

	logout = () => {
		if (!isBrowser) return

		this.localLogout()
		this._AUTH &&
			this._AUTH.logout({
				// returnTo: window.location.origin
				returnTo: 'https://resyk.org/'
			})
	}

	isAuthenticated = () => {
		if (!isBrowser) return false

		return localStorage.getItem('isLoggedIn') === 'true'
	}
}

const auth = new Auth()

export default auth
